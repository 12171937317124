import { Switch, useColorMode } from '@chakra-ui/react'
import Head from 'next/head'
import React from 'react'
import { COLOR_MODE } from 'constants/common'

export const ThemeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      <Head>
        <link rel="icon" type="image/svg" href={`/favicon_${colorMode}.svg`} />
      </Head>
      <Switch
        isChecked={colorMode === COLOR_MODE.DARK}
        onChange={() => {
          toggleColorMode()
          // https://stackoverflow.com/questions/74511193/smooth-transition-dark-light-mode-chakraui
          const styleEl = document.createElement('style')
          const cssText = document.createTextNode(
            'html * { transition: all 0.3s ease-out !important }',
          )
          styleEl.appendChild(cssText)
          document.head.appendChild(styleEl)
          setTimeout(() => {
            document.head.removeChild(styleEl)
          }, 300)
        }}
      />
    </>
  )
}
